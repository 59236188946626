<template>
  <div>
    <ProfileCard></ProfileCard>
  </div>
</template>

<script>
import ProfileCard from "../components/ProfileCard";
export default {
  sockets: {
    connect: function () {
      console.log("socket connected!!!!");
    },
    // customEmit: function (data) {
    //     console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    // }
  },
  components: {
    ProfileCard,
  },
};
</script>

<style lang="scss" scoped>
</style>